.submit_button {
background-color: #296CED;
color: white;
padding: 10px 20px;
border: none;
font-size: 18px;
font-weight: 500;
}
.form_details p {
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}
.radio_button {
    padding: 4px !important;
}
.addrequisiton_field input {
    padding: 11.5px 14px !important;
}
.addrequisiton_field label {
    top: -4px !important;
}
.dashboard_border_box {
    border: 1px solid #D6D6D6;
    border-radius: 10px;
    padding: 6px;
}
.module_table th {
    background: #EEEEEE;
}
.module_table th {
   padding: 10px;
   color: #202224;
   font-size: 14px;
   font-weight: 500;
}
.module_table td {
    padding: 5px;
}
.table_input input {
    width: 90% !important;
    border: 1px solid #E3E3E3 !important;
}
.data_field input {
    padding: 2.5px 4px 2.5px 5px !important;
}
.data_field label {
    top: -4px !important;
}
.listing_groups {
  margin-top: 20px;
}
.login_wrap {
    display: flex;
    gap: 50px;
    /* flex-wrap: wrap; */
}
.login_details {
  width: calc(70% - 30px);
  /* margin: 15px; */
  padding: 20px 30px;
}
.listing_holder {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.ssts_details h2 {
  text-align: center;
  color: #001271;
  font-size: 50px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  margin-bottom: 0px;
}
.ssts_details p {
  text-align: left;
  color: #313131;
  font-size: 18px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  margin-bottom: 0px;
}
.ssts_details h4 {
  text-align: left;
  color: #313131;
  font-size: 30px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  margin-bottom: 0px;
}
.login_background {
    background-image: url(../../../images/background.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    min-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.startup_details  h2 {
    text-align: center;
    color: white;
    font-size: 50px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    margin-top: 10px;
    margin-bottom: 0px;
  }
  .startup_details  p {
    text-align: center;
    color: white;
    font-size: 18px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
    margin-bottom: 0px;
  }
  .welcom_back_text h2 {
    text-align: center;
    color: #212121;
    font-size: 48px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }
  .welcom_back_text p {
    text-align: center;
    color: #212121;
    font-size: 32px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .investor_login_card {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    // width: 100%;
    // border-radius: 12px;
    // padding: 40px 40px;
    width: 60%;
    border-radius: 12px;
    padding: 40px 30px;
  }
  .login_card {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 100%;
    border-radius: 12px;
    padding: 40px 40px;
  }
  .forgot_details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .forgot_password_text p {
    color: #296CED;
    font-size: 16px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }
  .remember_text {
    color: #737373;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  .login_button_outer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  .login_tabs div div {
    justify-content: center;
    display: flex;
    gap: 40px;
  }
  .login_inputfield div {
    padding: 0;
  }
  .login_inputfield {
    margin-top: 15px;
  }
  .login_button {
    background-color: #296CED;
    border: none;
    color: white;
    font-family: "Poppins", sans-serif;
    padding: 9px 90px;
    width: 100%;
    cursor: pointer;
  }
  .inputfiled_icon {
    margin-left: 0 !important;
  }
  .rement_checkbox {
    margin-right: 0 !important;
  }
  .dashbord_table{
    width: 100%;
  }
  .dashbord_table th {
    background: #EEEEEE;
}
.dashbord_table th {
   padding: 10px;
   color: #202224;
   font-size: 14px;
   font-weight: 500;
}
.dashbord_table td {
    padding: 5px;
}
.chart_header {
    color: #000000;
    font-size: 22px;
    font-weight: 400;
    padding: 6px;
}
.organization_text {
    color: #000000;
    font-size: 20px;
    font-weight: 600;
    padding: 6px;  
}
.dashboard_div h5 {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    padding: 6px;    
}
.organization-details {
    grid-gap: 10px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
}
.organization-detailsdiv {
    grid-gap: 10px;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(5, 1fr);
    width: 100%;
    border-top: 1px solid #CCCCCC;
}
.border_right {
    border-right: 1px solid #CCCCCC;
}
.organization-details_text {
    color: #646464;
    font-size: 12px;
    font-weight: 400;   
}
.organization-profile_details {
    color: #000000;
    font-size: 14px;
    font-weight: 400;  
}
.investor_scroll {
  max-height: 280px;
  min-height: 280px;
  overflow-y: auto;
  overflow-x: hidden;
}
.investor_scroll::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.investor_scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
.investor_scroll::-webkit-scrollbar-thumb {
  background: rgb(184, 183, 183); 
  border-radius: 10px;
}

/* Handle on hover */
.investor_scroll::-webkit-scrollbar-thumb:hover {
  background: rgb(184, 183, 183); 
}
.approve_button {
  background-color: transparent;
  border: 1px solid #34B53A;
  color: #34B53A;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 15px;
}
.decline_button {
  background-color: transparent;
  border: 1px solid #FF4949;
  color: #FF4949;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  padding: 6px 15px;
}
.investor_list h3 {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.investor_list {
  border-bottom: 1px solid #DFDFDF;
}
.profile_imgouter h5 {
  color: #555A75;
  font-size: 16px;
  font-weight: 400;
}
.profile_imgouter p{
  color: #555A75;
  font-size: 14px;
  font-weight: 400;
}
.rounded_profile {
  object-fit: contain;
  height: 35px;
  justify-content: center;
  width: 35px;
  border-radius: 50% !important;
}
.investor_card {
  display: flex;
  /* gap: 20px;  */
  flex-wrap: wrap;
  min-height: 400px;
  max-height: 400px;
}
.investor_card_img {
  width: calc(33% - 10px);
}
.investor_card_img img {
 width: 100%;
 height: 100%;
}
.investor_inner_card {
  cursor: pointer;
  width: 100%;
}
.investor_inner_card img {
width: 100%;
}
.series_details {
  color: #296CED;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #EAF0FD;
}
.investor_poolard {
  border-radius: 10px;
}
.card_details_text h4 {
  color: #05394D;
  font-size: 18px;
  font-weight: 500;
}
.card_details_text p {
  color: #05394D;
  font-size: 16px;
  font-weight: 400;
}
.request_databutton {
  border: none;
  width: 100%;
  padding: 8px;
}
.pending_request_color{
  background-color: #fafca8;
  color: #f3db29
}
.approved_request_color{
  background-color: #DEFFED;
  color: #05AE53
}
.default_request_color{
  background-color: #DEFFED;
  color: #7b99f3
}
.create_button {
  border:none;
  padding: 10px 15px;
  color: white;
  border-radius: 10px;
  background-color: #05AE53;
  display: flex;
  gap: 5px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 15px;
  align-items: center;
  margin-right: 10px;
}
.insights_button {
  background-color: #EAF4FF;
  color: #0075FF;
  border: none;
  width: 100%;
  padding: 8px;
}

.link-class:hover {
  color: rgb(159, 159, 162);
  text-decoration: underline;
  text-decoration-color: rgb(159, 159, 162);
  ;
}
.investor_profile_outer_div {
  margin-top: -17px;
  display: flex;
  justify-content: center;
}
.investor_profile_outer {
  object-fit: contain;
  height: 40px;
  justify-content: center;
  width: 40px;
  border-radius: 50% !important;
}
.check_name {
  display: flex;
  justify-content: center;
  align-items: center;
}
.check_name h4 {
  color: #212121;
  font-size: 22px;
  font-weight: 500;
}
.investor_card_background {
  background-color: #F4F7FE;
  padding: 20px 8px;
}
.profile_border_bottom {
  border-bottom: 1px solid #EAF0FD;
}
.investment_button {
  background-color: #FFF8E0;
  border: none;
  width: 100%;
  padding: 7px 15px;
  text-align: left;
}
.investment_button span {
  color: #666666;
  font-size: 16px;
  font-weight: 400;
}
.investment_button p {
  color: #05394D;
  font-size: 21px;
  font-weight: 400;
}
.easy_apply_box {
  background-color: #FAB607;
  color: white;
  border: none;
  max-height: 78px;
  min-height: 78px;
  width: 90px;
  padding: 20px;
  border-radius: 68px 0px 0px 0px;
  display: flex;
  justify-content: end;
  position: absolute;
  right: -2px;
}
.easy_apply_box h5 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.profile_listing {
  color: #05394D;
  font-size: 16px;
  font-weight: 400;
}
.select_check {
  color: #296CED;
}
.upload_button {
  border: 1px solid #296CED;
  padding: 8px 24px;
  color: #296CED;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}